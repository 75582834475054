import React, { useEffect } from 'react'
import { Link } from 'react-scroll'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Logo from '../assets/working1.svg'

const Home = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div
      name='home'
      className='w-full h-screen block xl:flex items-center justify-center xl:justify-between px-4 md:px-8 mt-40 xl:mt-0'
    >
      <div>
        <h1
          data-aos='fade-down'
          data-aos-duration='2500'
          className='main mt-16 xl:mt-0 text-[32px] sm:text-[52px] lg:text-[72px]'
        >
          Birat Dev Poudel
        </h1>
        <hr className='hidden xl:flex' />
        <div
          data-aos='fade-up'
          data-aos-duration='2500'
          className='text-[12px] sm:text-[32px]  text-transparent  bg-gradient-to-br from-violet-500 to-blue-400  flex  bg-clip-text leading-loose tracking-wide '
        >
          Front-end Designer & Developer.
        </div>
        <div data-aos='fade-up' data-aos-duration='3000' className='mt-4'>
          <Link
            className='flex items-center'
            to='about'
            smooth={true}
            duration={900}
          >
            <button className='btn'>
              <span className='btn__text'>about me</span>
            </button>
          </Link>
        </div>
      </div>
      <div className='flex items-center justify-center logo mt-16'>
        <img
          className='h-[300px] sm:h-[400px] w-[500px] cursor-pointer rounded'
          src={Logo}
          alt='homesection'
        />
      </div>
    </div>
  )
}

export default Home

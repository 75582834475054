import React from 'react'
import { motion } from 'framer-motion'

export default function SubmitButton({ text, specialClass }) {
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      transition={{ bounce: 1 }}
      className={`bg-gradient-to-tr from-red-400 to-blue-600 text-white rounded cursor-pointer uppercase text-[16px] tracking-wide font-medium ${specialClass}`}
      type='submit'
    >
      {text}
    </motion.button>
  )
}

import React from 'react'
import { motion } from 'framer-motion'

export default function Button({ text, specialClass }) {
  return (
    <motion.div
      whileHover={{ scale: 0.9 }}
      whileTap={{ scale: 0.7 }}
      transition={{ bounce: 1 }}
      className={`bg-gradient-to-tl from-blue-700 to-violet-500 text-white rounded cursor-pointer uppercase text-[16px] tracking-wide font-medium ${specialClass}`}
    >
      {text}
    </motion.div>
  )
}

import React, { useEffect } from 'react'
import { FaCopyright } from 'react-icons/fa'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { FaGithub, FaInstagram, FaTwitter } from 'react-icons/fa'

function Footer() {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div className='head w-full flex text-center items-center justify-center mt-32'>
      <div className='w-[90%] text-center'>
        <div className='mb-10'>
          {' '}
          <ul className='flex items-center justify-center space-x-16 md:space-x-28'>
            <li className=''>
              <a
                href='https://www.twitter.com/sirgilmour'
                target='blank_'
                className='flex justify-between items-center w-full text-white hover:text-teal-500 hover:scale-90 transition duration-200 mr-3'
              >
                <FaTwitter size={30} />
              </a>
            </li>
            <li className=''>
              <a
                href='https://www.github.com/biratdevpoudel'
                target='blank_'
                className='flex justify-between items-center w-full text-white hover:text-teal-500 hover:scale-90 transition duration-200 mr-3'
              >
                <FaGithub size={30} />
              </a>
            </li>
            <li className=''>
              <a
                href='https://www.instagram.com/biratpoudel_'
                target='blank_'
                className='flex justify-between items-center w-full text-white hover:text-teal-500 hover:scale-90 transition duration-200 mr-3'
              >
                <FaInstagram size={30} />
              </a>
            </li>
          </ul>
        </div>
        <hr />
        <center className='text-teal-500 text-md sm:text-lg flex items-center justify-center m-2 p-2 tracking-wider'>
          Copyright
          <span className='ml-2 mr-2'>
            <FaCopyright />
          </span>
          2022 , Birat Dev Poudel
        </center>
      </div>
    </div>
  )
}

export default Footer

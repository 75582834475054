import React, { useState } from 'react'
import Logo from '../assets/Logo.svg'
import { Link } from 'react-scroll'
import Button from './Buttons/Button'

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)

  return (
    <div className='head fixed z-10 w-full h-[80px] flex justify-between items-center p-5 sm:p-10 bg-black text-white'>
      <div className='logo flex md:hidden'>
        <img className='h-[50px] w-[50px]' src={Logo} alt='Logo' />
      </div>
      <div className='navigation'>
        <ul className='hidden md:flex items-center space-x-10 lg:space-x-20 text-lg cursor-pointer font-bold tracking-wider'>
          <li className='hover:underline hover:underline-offset-4 hover:scale-90 hover:text-gray-300 transition-all hover:text-opacity-90 duration-700 border-white'>
            <Link to='home' smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className='hover:underline hover:underline-offset-4 hover:scale-90 hover:text-gray-300 transition-all hover:text-opacity-90 duration-700  border-white'>
            <Link to='about' smooth={true} duration={700}>
              About
            </Link>
          </li>
          <li className='hover:underline hover:underline-offset-4 hover:scale-90 hover:text-gray-300 transition-all hover:text-opacity-90 duration-700  border-white'>
            <Link to='skills' smooth={true} duration={900}>
              Skills
            </Link>
          </li>
        </ul>
      </div>
      <div>
        <ul className='hidden md:flex cursor-pointer'>
          <Link to='contact' smooth={true} duration={1100}>
            <Button text='contact' specialClass='px-3 py-2' />
          </Link>
        </ul>
      </div>

      {/* Hamburger Menu  */}
      <div
        onClick={handleClick}
        className='md:hidden z-10 group relative w-6 h-5 cursor-pointer flex-col justify-between items-center flex'
      >
        <span
          className={`h-[2px] w-full bg-white rounded-lg cursor-pointer transform transition duration-300 ease-in-out ${
            nav ? 'rotate-45 translate-y-2.5' : ''
          }`}
        />
        <span
          className={`h-[2px] w-full bg-white rounded-lg cursor-pointer transition-all duration-300 ease-in-out ${
            nav ? 'w-0' : 'w-full'
          }`}
        />
        <span
          className={`h-[2px] w-full bg-white rounded-lg cursor-pointer transform transition duration-300 ease-in-out ${
            nav ? '-rotate-45 -translate-y-2' : ''
          }`}
        />
      </div>

      <div
        className={`absolute md:hidden top-20 left-0 flex flex-col justify-center items-center cursor-pointer ${
          nav
            ? 'w-full transition-all duration-700'
            : 'w-0 transition-all duration-700'
        }`}
      >
        <ul
          className={`flex flex-col items-start justify-start w-[90%] space-y-2 sm:space-y-4 bg-gradient-to-r from-black via-black/50 to-transparent ring-1  p-4  ring-white/50 rounded ${
            nav
              ? 'visible ml-0 transition-all duration-300'
              : 'invisible -ml-52 transition-all duration-300'
          }`}
        >
          <li className='text-lg hover:underline hover:underline-offset-4'>
            <Link onClick={handleClick} to='home' smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className='text-lg hover:underline hover:underline-offset-4'>
            <Link
              activeClass='active'
              onClick={handleClick}
              to='about'
              smooth={true}
              duration={700}
            >
              About
            </Link>
          </li>
          <li className='text-lg hover:underline hover:underline-offset-4'>
            <Link
              onClick={handleClick}
              to='contact'
              smooth={true}
              duration={1000}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar

import React, { useEffect } from 'react'
import SubmitButton from './Buttons/SubmitButton'
import Aos from 'aos'
import 'aos/dist/aos.css'

function Contact() {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div
      name='contact'
      className='w-full h-screen flex flex-col justify-center items-center p-4 mb-12'
    >
      <div className='text-center pb-8'>
        <p data-aos='fade-up' data-aos-duration='1500' className='section'>
          Contact Me
        </p>
        <hr />
        <p
          data-aos='fade-up'
          data-aos-duration='2000'
          className='m-4 text-sm md:text-xl text-teal-500'
        >
          submit the form below to reach me.
        </p>
      </div>
      <div className='p-6'>
        <form
          action='https://formsubmit.co/edfa24b205a0a38a5f07370d2dd86d68'
          method='post'
          className='flex flex-col items-center justify-center space-y-6'
        >
          <div className='relative opacity-50'>
            <input
              required
              type='text'
              className='h-16 w-[42vh] md:w-[55vh] lg:w-[70vh] outline-none bg-transparent border p-2 rounded text-white'
            />
            <label className='text-white input-text left-0 top-5 absolute px-2 transition duration-300 capitalize'>
              Name
            </label>
          </div>
          <div className='relative opacity-50'>
            <input
              required
              type='email'
              className='h-16 w-[42vh] md:w-[55vh] lg:w-[70vh] outline-none bg-transparent border p-2 rounded text-white'
            />
            <label className='text-white input-email left-0 top-5 absolute px-2 transition duration-300 capitalize'>
              Email
            </label>
          </div>
          <div className='relative opacity-50'>
            <input
              required
              type='text'
              className='h-16 w-[42vh] md:w-[55vh] lg:w-[70vh] outline-none bg-transparent border p-2 rounded text-white'
            />
            <label className='text-white input-text left-0 top-5 absolute px-2 transition duration-300 capitalize'>
              Subject
            </label>
          </div>
          <div className='relative opacity-50'>
            <textarea
              required
              type='text'
              className='h-28 w-[42vh] md:w-[55vh] lg:w-[70vh] outline-none bg-transparent border p-2 rounded text-white'
            />
            <label className='text-white input-text left-0 top-1 absolute px-2 transition duration-300 capitalize'>
              Your Message
            </label>
          </div>
          <div
            data-aos='fade-up'
            data-aos-duration='1500'
            className='flex justify-center items-center mt-4'
          >
            <SubmitButton text='Submit' specialClass='px-6 py-3' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Contact

import React, { useEffect } from 'react'
import Logo from '../assets/Working.svg'
import { Link } from 'react-scroll'
import Aos from 'aos'
import 'aos/dist/aos.css'
import SecondaryButton from './Buttons/SecondaryButton'

const About = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div name='about' className='w-full h-auto sm:h-screen text-[#E1FCFD]'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='sm:text-right sm:pl-4 md:text-center'>
          <p data-aos='fade-up' data-aos-duration='1500' className='section'>
            about me
          </p>
          <hr />
        </div>
        <div className='max-w-7xl w-full items-center justify-center grid md:grid-cols-2 gap-16 px-4 mt-28'>
          <div
            className='flex items-center justify-center'
            data-aos='fade-right'
            data-aos-duration='2500'
          >
            <img className='w-[90%]' src={Logo} alt='Working' />
          </div>
          <div className=''>
            <p
              data-aos='fade-left'
              data-aos-duration='2500'
              className='text-teal-500 items-center text-left lg:text-justify justify-evenly tracking-wider leading-loose'
            >
              <h1 className='main text-3xl font-bold  mb-2'>I'm Birat,</h1>
              Software Engineering student currently studying in Gandaki College
              of Engineering and Science situated in Lamachour-Pokhara, Nepal.
              I'm passionate about Tech, Music, Movie, Finance and Investing.
              <div className='mt-5'>
                <Link
                  className='flex items-center'
                  to='skills'
                  smooth={true}
                  duration={900}
                >
                  <SecondaryButton text='skills' specialClass='px-6 py-3' />
                </Link>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About

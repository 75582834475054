import React, { useState, useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Home from './components/Home'
import Navbar from './components/Navbar'
import Skills from './components/Skills'
import { motion } from 'framer-motion'

function App() {
  useEffect(() => {
    Aos.init()
  }, [])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])
  const icon = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
    },
  }
  return (
    <div className='App'>
      {loading ? (
        <div className='flex justify-center items-center w-full h-screen overflow-hidden'>
          <div className='flex justify-center items-center'>
            <div className='logo'>
              <motion.svg
                width='469'
                height='362'
                viewBox='0 0 469 362'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='w-[200px] h-[100px]'
              >
                <motion.path
                  d='M6 6V122.722M6 122.722V219.444C6 230.49 14.9543 239.444 26 239.444H197.42C208.466 239.444 217.42 230.49 217.42 219.444V143.173C217.42 132.146 208.495 123.199 197.467 123.173L6 122.722Z'
                  stroke='white'
                  stroke-width='12'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  variants={icon}
                  initial='hidden'
                  animate={{
                    pathLength: 1,
                  }}
                  transition={{
                    duration: 1,
                    yoyo: Infinity,
                    ease: 'easeInOut',
                    repeatDelay: 2,
                  }}
                />
                <motion.path
                  d='M252.42 241.769H442.372C453.418 241.769 462.372 232.814 462.372 221.769V142.888C462.372 131.842 453.418 122.888 442.372 122.888H272.42C261.374 122.888 252.42 131.842 252.42 142.888V356'
                  stroke='white'
                  stroke-width='12'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  variants={icon}
                  initial='hidden'
                  animate='visible'
                  transition={{
                    delay: 1,
                    duration: 1,
                    yoyo: Infinity,
                    ease: 'easeInOut',
                    repeatDelay: 1,
                  }}
                  exit={{
                    stroke: '#000',
                  }}
                />
              </motion.svg>
            </div>
          </div>
        </div>
      ) : (
        <div className='overflow-hidden'>
          <Navbar />
          <Home />
          <About />
          <Skills />
          <Contact />
          <Footer />
        </div>
      )}
    </div>
  )
}

export default App

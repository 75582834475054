import React, { useEffect } from 'react'
import JavaScript from '../assets/js.png'
import Python from '../assets/python.png'
import PostgreSQL from '../assets/postgreSQL.svg'
import ReactJS from '../assets/react.png'
import Tailwind from '../assets/tailwindcss.svg'
import NextJS from '../assets/next.png'
import Django from '../assets/django.svg'
import GIT from '../assets/git.png'
import FIGMA from '../assets/figma.png'
import TypeScript from '../assets/typescript.jpeg'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Skills = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div name='skills' className='w-full h-screen text-[#E1FCFD] mb-16'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='text-center'>
          <p data-aos='fade-up' data-aos-duration='1500' className='section'>
            my skills
          </p>
          <hr />
          <p
            data-aos='fade-up'
            data-aos-duration='2000'
            className='m-4 text-sm md:text-xl text-teal-500'
          >
            technologies that i am famaliar with.
          </p>
        </div>
        <div className='block lg:flex items-center justify-center space-y-16 lg:space-y-0 space-x-0 lg:space-x-14 cursor-pointer mt-16'>
          <div className='flex space-x-5 md:space-x-14 items-center justify-center'>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[60px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={FIGMA}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                Figma
              </p>
            </div>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={Tailwind}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                Tailwind
              </p>
            </div>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={JavaScript}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                JavaScript
              </p>
            </div>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={ReactJS}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                ReactJS
              </p>
            </div>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={NextJS}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                NextJS
              </p>
            </div>
          </div>
          <div className='flex space-x-5 md:space-x-14 items-center justify-center'>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={TypeScript}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                TypeScript
              </p>
            </div>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={Python}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                Python
              </p>
            </div>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={Django}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                Django
              </p>
            </div>
            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={PostgreSQL}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                PostgreSQL
              </p>
            </div>

            <div className='text-center items-center justify-center flex flex-col'>
              <img
                className='w-[50px] grayscale hover:grayscale-0 hover:scale-110 duration-300'
                src={GIT}
                alt=''
                srcset=''
              />
              <p className='text-center text-[12px] font-thin mt-4 text-gray-300'>
                Git
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
